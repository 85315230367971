import { render, staticRenderFns } from "./WxPayDesc.vue?vue&type=template&id=39727831&scoped=true&"
var script = {}
import style0 from "./WxPayDesc.vue?vue&type=style&index=0&id=39727831&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39727831",
  null
  
)

export default component.exports